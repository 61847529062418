/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');

//! causing multiple stylying @import url(../styles/tailwind.scss);


*{
    font-family: "Figtree", sans-serif;
}
@import url('../../node_modules/iconsax-font-icon/dist/icons.css');
@import "~@ng-select/ng-select/themes/default.theme.css";
// @import "~@ng-select/ng-select/themes/material.theme.css"
$base-color:'#7743DB';
$base-color-black:'#384244';
$dark:#243056;

//variables
:root {
    --signup-bg: linear-gradient(180deg, #380E7F 0%, #7743DB 104.49%);
  }

.content-area div, p {
    color: $dark;
}
.content-area{
    background:rgba($color: #000, $alpha: 0.01);
}
.border-b{
    border-bottom: 1px solid rgba($color: $dark, $alpha: 0.1) !important;
}
.dark-bg{
    background: $dark !important;
}

.page-title{
   @extend .app-page-title;
}
.heading{
    font-size:16px;
    font-weight: 500;
}
.disable-scroll {
    overflow: hidden;
}

.visible-table{
    visibility: visible;
}
.hidden-table{
    visibility: hidden;
}

table.sf-table{
    // border:1px solid red;
    // display: none;

    .mat-mdc-cell{
        font-size: 14px !important;
        padding:0px 10px;
    }

    // Apply styles only when `.inner-table` is NOT present
    &:not(.inner-table) {
        thead {
          //  border-bottom: 1px solid #384244 !important;
        }
    }

    // Apply styles only when `.inner-table` is present
    &.inner-table {
        thead {
            border-bottom: none !important;
        }
    }

    thead{
        // border:1px solid red !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2)!important;
        position: relative;

        tr.mat-mdc-header-row{
            position: relative;
            // border:1px solid red !important;
            height: 40px;
            color:rgb(36, 48, 86)!important;
            th.mat-mdc-header-cell{
                font-size: 14px;
                font-weight: 600;
                color:rgb(36, 48, 86)!important;
                border-bottom: rgba(47,62,112, 0.2) !important;
                // height: 40px !important;
                // border:1px solid red !important;
            }
        }
    }
   
    thead::after{
        content: "";
        height: 1px;
        width: 100%;
        position: absolute;
        background-color: $base-color-black;
        bottom: 2px;
    }

    &:not(.custom) {// default row styles
        // Applying row styles for tbody when .custom is not present
        tbody.mdc-data-table__content {
            tr.mat-mdc-row:nth-child(odd) {
                background-color: rgba(246, 247, 248, 0.5);
            }

            tr.mat-mdc-row:nth-child(even) {
                background-color: #fff;
            }

            tr.mat-mdc-row:hover {
                background-color: rgba(119, 67, 219, 0.05) !important;
            }
        }
    }

    &.inner-table {// for inner table rows
        thead{
            tr.mat-mdc-header-row{
              // height: 45px !important;
              th.mat-mdc-header-cell{
                padding-top: 2px !important;
                &:first-child {
                  border-top-left-radius: 5px; 
                  border-bottom-left-radius: 5px;
                }
                &:last-child {
                  border-top-right-radius: 5px;
                  border-bottom-right-radius: 5px;
                }
            }
            }
          }
        // Applying row styles for tbody when .custom is not present
        tbody.mdc-data-table__content {
            tr.mat-mdc-row:nth-child(odd) {
                background-color: #fff !important;
            }

            tr.mat-mdc-row:nth-child(even) {
                background-color: #F6F7F8 !important;
            }

            tr.mat-mdc-row:hover {
                background-color: rgba(119, 67, 219, 0.05) !important;
            }
        }
    }

    &.custom{// for rows of tables with nested table
        // Applying row styles for tbody when .custom is not present
        tbody.mdc-data-table__content {
            tr.mat-mdc-row:nth-child(4n+1),
            tr.mat-mdc-row:nth-child(4n+2) {
            background-color: #F6F7F8;
            }

            tr.mat-mdc-row:nth-child(4n+3),
            tr.mat-mdc-row:nth-child(4n+4) {
            background-color: #fff;
            }

            tr.mat-mdc-row:hover {
                background-color: rgba(119, 67, 219, 0.05) !important;
            }
        }
    }
}
.mat-mdc-paginator-container{

    position: absolute;

    width: 100%;

    bottom: 0px;

    display: flex;
    
    justify-content:space-between !important;

    padding:10px 0px !important;

    margin: 10px 0;

    // border:1px solid red;

    .mat-mdc-paginator-page-size{
        margin-left: 0px !important;
        // border:1px solid red;
        min-width: 250px;
        .mat-mdc-paginator-page-size-label{
            display: none !important;
        }
        .mat-mdc-form-field-infix{
            width: auto;

        }
        .mat-mdc-paginator-page-size-select{
            // max-width: 80px;
            width: auto;
        
        }
    }


    .mat-mdc-paginator-range-actions{

        // border:1px solid red;
        box-shadow: 0px 2px 5px #D9DEE2;
        border-radius: 5px;

    
        .mat-mdc-paginator-range-label{
            display: none;

        }
    
        .mat-mdc-paginator-navigation-first,.mat-mdc-paginator-navigation-previous,
        .mat-mdc-paginator-navigation-next, .mat-mdc-paginator-navigation-last, .mat-custom-page{
    
            // border:1px solid blue;   
            border-radius: 0;  
            height: 38px;
            width: 38px;
            font-size: 14px;
            padding:5px;
            border:none;
            border-right: .5px solid rgba(191, 205, 216, 1);
        }
        .mat-button:hover{
            background-color: #384244;
        }

        .mat-button{
            // border:1px solid red;
        }

        
    
    
        .mat-mdc-paginator-navigation-first{
    
        }
    
        .mat-mdc-paginator-navigation-previous{
    
        }
    
        .mat-mdc-paginator-navigation-next{
    
        }
    
        .mat-mdc-paginator-navigation-last{

            border-right: none;
    
        }
    
        .mat-custom-page{
            // padding:10px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding:6px 0;
            cursor: pointer;
        }
        .mat-custom-page:hover, .mat-custom-page:disabled, .mat-custom-page[disabled]{
            // border:1px solid red;
            background-color: rgba(119, 67, 218, 1);
            color: white;
            display: flex;
            text-align: center;
            justify-content: center;
        }
    }
}
.mat-mdc-paginator-container>.mat-mdc-paginator-range-label{
    // border:1px solid red;
    font-size: 14px;
}
table-card{
    width: 100%;
    height: 100%;
    padding: 14px;
}

::ng-deep .mat-sort-header-arrow[style] {
    // Hide default arrow stem
    .mat-sort-header-stem {
      display: none;
    }
    .mat-sort-header-indicator {
      opacity: 1;
      color: black;
      font-weight: bold;
      // Hide default arrow as its composed of left, right and middle
      .mat-sort-header-pointer-left,
      .mat-sort-header-pointer-right,
      .mat-sort-header-pointer-middle {
        display: none;
      }
    }
  }
  // My custom ascending arrow
::ng-deep [aria-sort='ascending'] .mat-sort-header-arrow {
    .mat-sort-header-indicator {
    &::before {
        content: '\25B4';
        top: -0em;
        position: absolute;
        color: #004e87;
        font-size: 20px;
        line-height: 10px;
    }
    }
} 
  // My custom descending arrow
::ng-deep [aria-sort='descending'] .mat-sort-header-arrow {
    .mat-sort-header-indicator {
    &::before {
        content: '\25BE';
        top: -15px;
        position: absolute;
        color: #004e87;
        font-size: 20px;
    }
    }
}
.val-required::after {
    content: "*";
    color: #FF3838;
    font-size: 15px;
    font-weight: 500;
    margin-left: 2px;
}

.mat-mdc-form-field{
    font-size: 14px !important;
    letter-spacing: -1% !important;
    .mat-mdc-text-field-wrapper{
        .mat-mdc-form-field-flex{
            // height: auto;
            .mat-mdc-form-field-infix{
                padding-top:8px;
                padding-bottom: 8px;
                min-height: 40px !important;

                .mat-mdc-floating-label{
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: -0.28px;
                }

                .mat-mdc-select-value-text{
                    font-size: 14px;
                    // color: red;
                    color: #384244;
                }
                .mat-mdc-form-field-required-marker{
                    color: #FF3838 !important;
                }
            }
        }
    }
}

//* Custome Toggle Switch
.custom-switch {
    width: 31px;
    height: 20px;
    position: relative;
  
    // Hide default HTML checkbox
    .custom-control-input {
      opacity: 0;
      width: 0;
      height: 0;
      position: absolute;
    }
  
    .custom-control-label {
      width: 100%;
      height: 100%;
      display: block;
      background-color: #D9D9D9;
      border-radius: 16px;
      cursor: pointer;
      transition: all 0.2s ease-out;
  
      // The slider
      .slider {
        width: 15.5px;
        height: 15.5px;
        position: absolute;
        left: calc(50% - 15.5px / 2 - 6px);
        top: calc(50% - 15.5px / 2);
        border-radius: 50%;
        background: #fff;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
        transition: all 0.2s ease-out;
        cursor: pointer;
      }
    }
    .custom-control-input:checked + .custom-control-label {
      background-color: #7743da;
      .slider {
        left: calc(50% - 15.5px / 2 + 6px);
        top: calc(50% - 15.5px / 2);
      }
    }
    &.custom-switch-success {
        .custom-control-input:checked + .custom-control-label {
          background-color: #0ABC7C;
        }
    }
}
.card-shadow{
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08))
}
.xl-shadow{
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3))  
}
.grid-avatar{
    width: 36px;
    height: 36px;
    padding: 2px;
}
*,
::before,
::after {
   // --tw-border-opacity: 1 !important;
    border-color: rgba(47,62,112, 0.2);
    .dark & {
        --tw-border-opacity: 0.12 !important;
    }
}
.mat-mdc-cell{
    border:none !important
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option, .ng-select .ng-select-container{
    color:rgb(36, 48, 86)!important;
    background:#fff;
    font-size:14px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked{
background: rgba(#7743da, 0.1);
color:$dark !important;
}
.ng-dropdown-panel .scroll-host{
    box-shadow: 0px 15px 15px rgba(25, 21, 48, 0.20);
}
.text-neutral-600{
    color:rgb(36, 48, 86)!important;
}
.rounded-lg{
    border-radius: 10px;
}
.inline{
    display: inline-block !important;
}
.left{
    float: left;
}
.right{
    float: right;
}
.app-link{
    text-decoration: underline;
    color:#246AD4;
    &:hover{
        text-decoration: underline;
    }
}
.app-page-title{
    font-size:18px;
    font-weight: 500;
}
.app-card{
    // padding: 10px !important;
    background: white;
    margin: 10px 20px !important;
    border-radius: 10px;
    @extend .card-shadow;
}

.mdc-button{
    font-size: 14px !important;
    color:rgb(36, 48, 86)
}
.fuse-mat-button-large {
    .mdc-button, span{
        font-size: 14px !important;
        color:white!important;
    }
}
.mat-mdc-paginator-range-actions{
    height: 30px !important;
    overflow: hidden;
}
.light-bg{
  background:#eff0f2;
}
.ml-10{
    margin-left:10px !important;
}
.main-button{
    height: 40px;
    color:white !important;
    background:#7743DB;
    border-radius: 6px !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
    display: inline-flex;
    align-items: center;
    padding:0 15px;
    font-weight: 400 !important;
   &:hover{
    background: darken($color: #7743DB, $amount: 15);
   }
}
.second-button{
    height: 40px;
    color:rgba(36, 48, 86, 1);
    background: #7743DB08;
    border:1px solid #7743DB80 !important;
    border-radius: 6px !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 400 !important;
    padding:0 15px;
   &:hover{
    background: #fff;
   }
}
.mat-mdc-option .mdc-list-item__primary-text, .mat-mdc-option{
    font-size: 14px !important;
    font-family: "Figtree", sans-serif !important;
}
.section-title{
    font-size:18px;
    font-weight: 500;
    color:$dark;
}
.theme-default .light .mat-mdc-unelevated-button.mat-warn, .theme-default.light .mat-mdc-unelevated-button.mat-warn{
    @extend .main-button;
}
.theme-default .light .mat-mdc-outlined-button, .theme-default.light .mat-mdc-outlined-button{
    @extend .second-button;
}
data-grid-filter{
    z-index: 10;
    position: relative;
}
.mat-mdc-paginator-page-size-select .mat-mdc-text-field-wrapper{
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.callcenter-container{
    border-left: 2px solid #fff;
}
.callcenter-container{
    .call-history{
        a{
            padding: 0 10px;
            &:hover{
                padding:0 10px;
            }
        }
    }
}
input.phone-input::placeholder {
    opacity: 1 !important;
    font-size:24px !important;
    letter-spacing: -0.5px !important;
  }
.bg-gray-200 {
    background:rgba(35,48,86,0.08) !important;
}

.bg-selected-row {
    background-color: #f0f2f2 !important;
  }